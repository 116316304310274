// src/environments/development.js

import environment from './base';

// const baseApi = 'https://server.salvationlamb.com/api/v1';
const baseApi = 'http://209.182.232.231:4000/api/v1';
// const baseApi = 'http://localhost:4000/api/v1';
const env = environment(baseApi);

const data = {
    ...env,
    isMock: false,
}

export default data;
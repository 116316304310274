
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import './App.scss';
import ForgotPassword from './app/forgotPassword/ForgotPassword';
import Loading from './component/loading';
import { getStatus } from './service/common/common.service';
import useWindowDimensions from './service/common/windows-size';
import { preferenceService } from './service/preference-service';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteAccount from './app/deleteAccount/DeleteAccount';

const Login = lazy(() => import("./app/login/Login"));
const Signup = lazy(() => import("./app/signup/Signup"));
const Main = lazy(() => import("./app/Main"));
const TermsService = lazy(() => import("./app/common/terms-service/TermsService"));
const Event = lazy(() => import("./app/event/Event"));
const PrivacyPolicy = lazy(() => import("./app/common/privacy-policy/PrivacyPolicy"));
const Video = lazy(() => import("./app/common/Video"));
const Preview = lazy(() => import("./app/main/files/Preview"));

const App = ({ history }) => {
    const { width } = useWindowDimensions();
    const [, setMobileView] = useState(false);

    useEffect(() => {
        if (preferenceService.isLive() && !window.location.href.includes('login')) {
            getStatus();
            // /history.push('/home');
        }
    }, [])

    useEffect(() => {
        function check() {
            if (width < 800) {
                setMobileView(true);
                localStorage.setItem('mobileView', true);
            }
            else {
                setMobileView(false);
                localStorage.setItem('mobileView', false);
            }
        }
        check();
    })
    return (
        <div className="App root-container p-0">
            <ToastContainer />
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route path="/" component={Login} exact={true} />
                    <Route path="/login" component={Login} exact={true} />
                    <Route path="/signup" component={Signup} exact={true} />
                    <Route path="/video/:id" component={Video} exact={true} />
                    <Route path="/preview/:file" component={Preview} exact={true} />
                    <Route path="/event/:id" component={Event} exact={true} />
                    <Route path="/terms" component={TermsService} exact={true} />
                    <Route path="/privacy" component={PrivacyPolicy} exact={true} />
                    <Route path="/forgotPassword" component={ForgotPassword} exact={true} />
                    <Route path="/account/delete" component={DeleteAccount} exact={true} />
                    <Route component={Main} />
                </Switch>
            </Suspense>
        </div>
    );
}

export default withRouter(App);
